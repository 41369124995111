import './login.less';

(function() {
    angular.module('EntrakV5').controller('refreshController', refreshController);

    function refreshController($scope, Service, Api, $state, $stateParams, IS_LOCAL) {
        console.log('refreshController');

        //$stateParams.cb?
        //$stateParams.landlord
        var from = $stateParams.cb ? decodeURIComponent($stateParams.cb) : null;

        $scope.goLogin = function() {
          $state.transitionTo('login', {
              landlord: $stateParams.landlord
          }, {
              notify: false,
              replace: true
          });
        }

        $scope.silentMsLogin = function(){
          var codes = Service.generalCodeVerifierAndChallenge();
          if (Service.storageSave('codeVerifier', codes.verifier))
              window.location.href = Api.getRequestMSCodeURL($stateParams.landlord === 'landlord', codes.challenge, true);
        }
        
        var method = Service.getCookie("et_method");
        if (IS_LOCAL) {
          console.log("localhost cannot refresh now, please sign in again");
          $scope.goLogin();
        } else if ("cognitoToken" === method) {
          console.log("trying to refresh cognito token...");
          var refreshToken = Service.getCookie("et_rt");
          Api.refreshCognitoToken(refreshToken).then(
            res => {
              Service.setCookie("et_method", "cognitoToken");
              Service.setCookie("et_it", res.id_token);
              Service.setCookie("et_at", res.access_token);
              if (res.refresh_token)
                Service.setCookie("et_rt", res.refresh_token);
              if (from)
                window.location = from;
            },
            () => {
              console.log("unabled to refresh cognito, go login");
              $scope.goLogin();
            }
          );
        } else if ("microsoft" === method) {
          console.log("trying to refresh ms token...");
          var refreshToken = Service.getCookie("et_rt");
          Api.refreshByRefreshToken(refreshToken, function(res){
            Service.setCookie("et_method", "microsoft");
            Service.setCookie("et_it", res.id_token);
            if (res.refresh_token)
              Service.setCookie("et_rt", res.refresh_token);
            if (from)
              window.location = from;
          }, function(err){
            console.log("unable to refresh, try slient signin...", err);
            $scope.silentMsLogin();
          });
        } else {
          console.log("cannot refresh", method);
          $scope.goLogin();
        }

        $scope.$on('$destroy', function() {
            console.log("refreshController destroy");
        });
    }
})();
